import { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { Stripe, loadStripe, PaymentIntent } from '@stripe/stripe-js';
import Api from '../../api';
import FullPageLoading from '../FullPageLoading';
import { TipDetails } from '../../interfaces/tip-details.interface';
import UserDropDown from '../../components/UserDropDown';
import TipBox from '../../components/TipBox';
import CustomTipBox from '../../components/CustomTipBox';
import {
  InformationCircleIcon,
  ArrowNarrowRightIcon,
  ChevronLeftIcon,
} from '@heroicons/react/solid';
// import Toggle from '../components/Toggle';
import CustomTipDialog from '../../components/CustomTipDialog';
import { ReactComponent as SelectedLogo } from '../../assets/tipping/Selected-Tipping Logov2.svg';
import { ReactComponent as BgBill } from '../../assets/tipping/bg-bill.svg';
import { formatCurrencyWithOutSymbol } from '../../utils/currency';
import CheckoutForm from '../../components/CheckoutForm';
import CopyRight from '../../components/CopyRight';
import Receipt from './components/Receipt';
import ReceiptFailed from './components/ReceiptFailed';
import { useTranslation } from 'react-i18next';
// import { SwitchLanguages } from '../../components/SwitchLanguages';
import TagManager from 'react-gtm-module';
import { tagManagerEventsName } from '../..';
import { useNetworkState } from 'react-use';
import { useErrorState } from '../../contexts/error-state-context';
import Unsplash from '../../components/Unsplash';
import FeedbackStep from './components/FeedbackStep';

export interface TB {
  id: string;
  active: boolean;
  percent: number;
  price: number;
}

export type STEP =
  | 'CREATE_TIP'
  | 'FEEDBACK'
  | 'PAY_TIP'
  | 'ReceiptSuccess'
  | 'ReceiptFail';

const Tip = () => {
  const networkState = useNetworkState();
  const errorState = useErrorState();
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [stripePromise, setStripePromise] =
    useState<Promise<Stripe | null> | null>(null);
  const [step, setStep] = useState<STEP>('CREATE_TIP');
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [feeLoading, setFeeLoading] = useState<boolean>(false);
  const [intentLoading, setInitentLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [feedBackId, setFeedBackId] = useState<string | null>(null);
  const [tipDetails, setTipDetails] = useState<TipDetails | null>(null);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [customTip, setCustomTip] = useState<number | null>(null);
  const [stripeCharge, setStripeCharge] = useState<number>(0);
  const [finalPrice, setFinalPrice] = useState<number>(0);
  const [stripeChargeToggle, setStripeChargeToggle] = useState<boolean>(true);
  const [stripeResponse, setStripeResponse] = useState<PaymentIntent | null>(
    null,
  );
  const [activeTipBox, setActiveTipBox] = useState<string | null>(null);
  let [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { tipCode } = useParams();
  let [params] = useSearchParams();
  const bill = params.get('bill');
  const orderId = params.get('orderId');
  const returnUrl = params.get('returnUrl');

  const handleStripeChargeToggle = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: tagManagerEventsName.addFees,
        tipCode,
        bill: bill ?? 0,
      },
    });
    setStripeChargeToggle(!stripeChargeToggle);
  };

  const fetchTip = useCallback(
    async (tipCode: string) => {
      try {
        const response = await Api.getServiceDetail(tipCode!);
        const data = response.data;
        setTipDetails(data);
        setActiveTipBox(data?.tippingConfigs?.[0]?._id ?? null);
        setInitialLoading(false);
        const sp = loadStripe(
          data.workspace === 'selected-neverq'
            ? `${process.env.REACT_APP_STRIPE_KEY_NEVERQ}`
            : `${process.env.REACT_APP_STRIPE_KEY_ESHOP}`,
        );
        setStripePromise(sp);
      } catch (e) {
        navigate('/');
      }
    },
    [navigate],
  );

  const getStripeCharge = useCallback(async (amount: number) => {
    try {
      setFeeLoading(true);
      const response = await Api.getStripeCharge(amount);
      const data = response.data;
      setStripeCharge(data.fee);
      setFeeLoading(false);
    } catch (e) {
      setFeeLoading(false);
      console.warn(e);
    }
  }, []);

  useEffect(() => {
    if (tipCode) {
      fetchTip(tipCode);
    }
  }, [fetchTip, tipCode]);

  useEffect(() => {
    if (customTip) {
      getStripeCharge(customTip);
    }
  }, [getStripeCharge, customTip]);

  useEffect(() => {
    let amount = 0;
    const activeTip = tipDetails?.tippingConfigs.find(
      tip => tip._id === activeTipBox,
    );
    if (activeTip) {
      amount = bill
        ? +((+bill / 100) * activeTip.configDetail.percentageAmount).toFixed(2)
        : activeTip?.configDetail.staticAmount;
      getStripeCharge(amount);
    }
  }, [getStripeCharge, bill, activeTipBox, tipDetails?.tippingConfigs]);

  useEffect(() => {
    let amount = 0;
    if (customTip) {
      amount = customTip;
    } else {
      const activeTip = tipDetails?.tippingConfigs.find(
        tip => tip._id === activeTipBox,
      );
      if (activeTip) {
        amount = bill
          ? +((+bill / 100) * activeTip.configDetail.percentageAmount).toFixed(
              2,
            )
          : activeTip?.configDetail.staticAmount;
      }
    }
    if (stripeChargeToggle) {
      amount = +(amount + stripeCharge).toFixed(2);
    }
    setFinalPrice(amount);
  }, [
    bill,
    customTip,
    stripeChargeToggle,
    stripeCharge,
    tipDetails?.tippingConfigs,
    activeTipBox,
  ]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (networkState.online === false) {
      errorState.setType('OFFLINE');
      return;
    }
    if (intentLoading && feeLoading) {
      return;
    }
    try {
      setInitentLoading(true);
      setError(null);
      const response = await Api.createPaymentIntent({
        amount: finalPrice,
        tipCode: tipCode!,
        ...(feedBackId && { feedBackId }),
        metadata: {
          bill: bill ?? '0',
          orderId: orderId ?? '',
          returnUrl: returnUrl ?? '',
          redirectUrl: 'https://landing.tipping.selectedstartups.com/',
        },
      });
      const result = response.data;
      setClientSecret(result.client_secret);
      setInitentLoading(false);
      goToPayStep();
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      TagManager.dataLayer({
        dataLayer: {
          event: tagManagerEventsName.createIntent,
          tipCode,
          bill: bill ?? 0,
        },
      });
    } catch (e) {
      setInitentLoading(false);
      setError('Sorry, There was an Error');
    }
  };

  const changeTipBox = (id: string) => {
    setActiveTipBox(id);
    setCustomTip(null);
  };

  const changeCustomBox = (num: number) => {
    setActiveTipBox(null);
    setCustomTip(num);
  };

  const goToPayStep = () => {
    setStep('PAY_TIP');
  };

  const goToCreateTipStep = () => {
    setStep('CREATE_TIP');
  };

  const goToFeedBackStep = () => {
    setStep('FEEDBACK');
  };

  const handleSuccessPayment = () => {
    setStep('ReceiptSuccess');
  };

  const handleFailedPayment = () => {
    setStep('ReceiptFail');
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  if (initialLoading) {
    return <FullPageLoading />;
  }

  if (step === 'ReceiptFail') {
    return (
      <ReceiptFailed
        amount={finalPrice}
        serviceName={tipDetails?.serviceName}
        businessName={tipDetails?.businessName}
        stripeRes={stripeResponse}
        handleTryAgain={goToPayStep}
      />
    );
  }

  if (step === 'ReceiptSuccess') {
    return (
      <Receipt
        amount={finalPrice}
        serviceName={tipDetails?.serviceName}
        businessName={tipDetails?.businessName}
        stripeRes={stripeResponse}
        returnUrl={returnUrl}
      />
    );
  }

  return (
    <div className="flex flex-col items-center min-h-screen bg-[#f9f9f9]">
      <Helmet>
        <title>{tipDetails?.serviceName}</title>
        <meta name="title" content={tipDetails?.serviceName} />
        <meta name="description" content={tipDetails?.serviceDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="{{tipping_url}}" />
        <meta property="og:title" content={tipDetails?.serviceName} />
        <meta
          property="og:description"
          content={tipDetails?.serviceDescription}
        />
        <meta
          property="og:image"
          content="https://cdn.myselected.org/tipping/image/landing/facebook-tipping.jpg"
        />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="{{tipping_url}}" />
        <meta property="twitter:title" content={tipDetails?.serviceName} />
        <meta
          property="twitter:description"
          content={tipDetails?.serviceDescription}
        />
        <meta
          property="twitter:image"
          content="https://cdn.myselected.org/tipping/image/landing/twitter-tipping.jpg"
        />
      </Helmet>
      <div className="flex flex-col items-center justify-center w-[calc(100%-32px)] sn:mx-4 mx-auto md:w-full max-w-[451px] pb-14">
        <Unsplash />
        <div className="flex items-center justify-center w-full my-4 lg:px-8">
          <SelectedLogo className="z-[3] w-[200px] lg:h-8 lg:block" />
          {/* <SwitchLanguages /> */}
        </div>
        {step === 'CREATE_TIP' && (
          <>
            <div className="bg-white p-4 lg:p-9 z-[3] rounded-lg lg:shadow-sm w-full">
              <UserDropDown tipDetails={tipDetails} />
            </div>
            <div className="flex flex-col p-4 my-4 lg:p-9 bg-white rounded-lg shadow-sm z-[3] w-full">
              {bill && !Number.isNaN(+bill) && (
                <div className="flex px-10 pl-4 justify-center flex-col rounded-[4px] bg-[#1E2F4D] h-28 w-full shadow overflow-hidden relative">
                  <div className="absolute bg-white rounded-full w-36 h-36 -top-16 -left-[64px] opacity-10"></div>
                  <BgBill className="absolute right-2 h-[70px] md:right-6" />
                  <span className="py-1 text-lg font-light text-zinc-300">
                    {t<string>('Your Bill Amount')}
                  </span>
                  <span className="relative py-1 pl-5 text-3xl text-zinc-300">
                    <div className="absolute top-0 left-0 text-lg font-semibold">
                      &#163;
                    </div>
                    {/* {bill && formatCurrency(bill)} */}
                    {(+bill).toFixed(2)}
                  </span>
                </div>
              )}
              <h1 className="py-7 pb-4 text-sm font-medium text-center text-[#1E2F4D]">
                {t<string>('How much would you like to tip?')}
              </h1>
              <div className="relative flex pb-5 overflow-x-auto scroll-smooth scrollbar">
                {tipDetails?.tippingConfigs.map(tipBox => (
                  <TipBox
                    key={tipBox._id}
                    id={tipBox._id}
                    active={tipBox._id === activeTipBox}
                    percent={tipBox.configDetail.percentageAmount}
                    price={tipBox.configDetail.staticAmount}
                    bill={bill}
                    changeTipBox={changeTipBox}
                  />
                ))}
                <CustomTipBox
                  openModal={openModal}
                  customTip={customTip}
                  bill={bill}
                />
                <CustomTipDialog
                  isModalOpen={isModalOpen}
                  closeModal={closeModal}
                  customTip={customTip}
                  changeCustomBox={changeCustomBox}
                />
              </div>
              <div className="flex items-center justify-center h-16">
                <input
                  type="checkbox"
                  className="w-5 h-5 mr-3 form-checkbox text-[#f2a313] border border-[#f2a313] rounded"
                  checked={stripeChargeToggle}
                  onChange={handleStripeChargeToggle}
                />
                <p className="text-sm font-medium text-[#1E2F4D]">
                  +&#163;{formatCurrencyWithOutSymbol(stripeCharge)}{' '}
                  {t<string>('card fees')}
                </p>
                <div className="tooltip">
                  <InformationCircleIcon
                    className="w-5 h-5 ml-2 text-[#f2a313] "
                    aria-hidden="true"
                  />
                  <span className="p-3 text-xs tooltiptext">
                    {t<string>(
                      'By helping to cover these fees as part of your tip, you ensure the recipient gets the full amount.',
                    )}
                  </span>
                </div>
              </div>
              {/* <RecordAudio tipCode={tipCode} setFeedBackId={setFeedBackId} setInitentLoading={setInitentLoading} /> */}
              <button
                type="submit"
                disabled={intentLoading}
                onClick={goToFeedBackStep}
                className=" relative p-0 transition h-[70px] mt-2 text-white rounded-[4px] bg-[#FEB930]"
              >
                {intentLoading ? (
                  <span className="text-lg font-medium text-white">
                    {t<string>('Processing…')}
                  </span>
                ) : (
                  <>
                    <span className="text-lg font-medium text-white">
                      {t<string>('Next')}
                    </span>
                    <ArrowNarrowRightIcon
                      className="absolute w-8 h-8 text-white top-[20px] right-[30px]"
                      aria-hidden="true"
                    />
                  </>
                )}
              </button>
              {error && (
                <span className="flex items-center justify-center py-2 mt-2 text-base font-medium text-red-400 capitalize">
                  {error}
                </span>
              )}
            </div>
          </>
        )}
        {step === 'FEEDBACK' && (
          <FeedbackStep
            prevStep={goToCreateTipStep}
            nextStep={goToPayStep}
            tipCode={tipCode}
            setFeedBackId={setFeedBackId}
            feedBackId={feedBackId}
            intentLoading={intentLoading}
            setInitentLoading={setInitentLoading}
            onSubmit={onSubmit}
          />
        )}
        {step === 'PAY_TIP' && (
          <>
            <div
              className="absolute z-50 flex items-center justify-center rounded-full shadow cursor-pointer w-9 h-9 left-5 top-5 bg-white/70"
              onClick={goToFeedBackStep}
            >
              <ChevronLeftIcon
                className="w-8 h-8 text-[#1E2F4D]"
                aria-hidden="true"
              />
            </div>
            <div className="flex flex-col p-4 lg:p-9 w-full bg-white rounded-lg mt-4 shadow-sm z-[3]">
              <div className="flex flex-col">
                <h1 className="mb-4 text-sm font-medium text-center text-[#1E2F4D]">
                  {t<string>('Total Payable Amount')}
                </h1>
                <div className="flex px-12 pl-4 justify-center flex-col rounded-[4px] bg-gradient-to-r from-[#c051ff] to-[#ffb400] h-28 w-full shadow overflow-hidden relative">
                  <div className="absolute bg-white rounded-full w-36 h-36 -top-16 -left-8 opacity-20"></div>
                  <BgBill className="absolute right-2 h-[70px] md:right-6" />
                  <span className="relative py-1 pl-5 text-3xl font-bold text-white">
                    <div className="absolute top-0 left-0 text-lg font-medium">
                      &#163;
                    </div>
                    {formatCurrencyWithOutSymbol(finalPrice)}
                  </span>
                  <div className="flex items-center text-white">
                    <span className="text-base">{t<string>('To')}:</span>
                    <span className="py-1 ml-2 text-lg font-medium capitalize lg:text-2xl line-clamp-1">
                      {tipDetails?.businessName}
                    </span>
                  </div>
                </div>
                <h1 className="mt-8 mb-4 text-sm font-medium text-center text-[#1E2F4D]">
                  {t<string>('Credit Card Information')}
                </h1>
                <Elements stripe={stripePromise}>
                  <CheckoutForm
                    clientSecret={clientSecret}
                    setStripeResponse={setStripeResponse}
                    handleSuccessPayment={handleSuccessPayment}
                    handleFailedPayment={handleFailedPayment}
                    finalPrice={finalPrice}
                  />
                </Elements>
              </div>
            </div>
          </>
        )}
        <div className="w-full z-[2]">
          <CopyRight />
        </div>
      </div>
    </div>
  );
};

export default Tip;
