import { useEffect, useState } from 'react';
import {
  CardElement,
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  PaymentRequestButtonElement,
} from '@stripe/react-stripe-js';
import { PaymentRequest, StripeElementStyleVariant } from '@stripe/stripe-js';
import stripeLogo from '../assets/stripe/stripe-badge-white.png';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { tagManagerEventsName } from '..';
import { useNetworkState } from 'react-use';
import { useErrorState } from '../contexts/error-state-context';

const inputStyle: StripeElementStyleVariant = {
  color: '#000000',
  fontWeight: '500',
  fontFamily: 'Poppins, sans-serif',
  fontSize: '16px',
  fontSmoothing: 'antialiased',
  ':-webkit-autofill': {
    color: '#fce883',
  },
  '::placeholder': {
    color: 'slategray',
  },
};

export default function CheckoutForm({
  clientSecret,
  setStripeResponse,
  handleSuccessPayment,
  handleFailedPayment,
  finalPrice,
}: {
  clientSecret: string | null;
  setStripeResponse: any;
  handleSuccessPayment: any;
  handleFailedPayment: any;
  finalPrice: any;
}) {
  const networkState = useNetworkState();
  const errorState = useErrorState();
  const { t } = useTranslation();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);
  const [postalCode, setPostalCode] = useState<string>('');
  const [disabled, setDisabled] = useState(true);
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest>();

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'GB',
        currency: 'gbp',
        total: {
          label: 'Tipping',
          amount: +(finalPrice.toFixed(2) * 100).toFixed(0) ?? 0,
        },
        requestPayerName: false,
        requestPayerEmail: false,
      });
      pr.on('paymentmethod', async ev => {
        // Confirm the PaymentIntent without handling potential next actions (yet).
        const { paymentIntent, error: confirmError } =
          await stripe.confirmCardPayment(
            clientSecret!,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false },
          );
        if (confirmError) {
          // Report to the browser that the payment failed, prompting it to
          // re-show the payment interface, or show an error message and close
          // the payment interface.
          ev.complete('fail');
        } else {
          // Report to the browser that the confirmation was successful, prompting
          // it to close the browser payment method collection interface.
          ev.complete('success');
          // Check if the PaymentIntent requires any actions and if so let Stripe.js
          // handle the flow. If using an API version older than "2019-02-11"
          // instead check for: `paymentIntent.status === "requires_source_action"`.
          if (paymentIntent.status === 'requires_action') {
            // Let Stripe.js handle the rest of the payment flow.
            const { error } = await stripe.confirmCardPayment(clientSecret!);
            if (error) {
              handleFailedPayment();
              // The payment failed -- ask your customer for a new payment method.
              return;
            }
          }
          TagManager.dataLayer({
            dataLayer: {
              event: tagManagerEventsName.completePurchase,
              finalPrice: finalPrice ?? 0,
            },
          });
          setStripeResponse(paymentIntent);
          handleSuccessPayment();
        }
      });
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [
    stripe,
    clientSecret,
    finalPrice,
    handleFailedPayment,
    handleSuccessPayment,
    setStripeResponse,
  ]);

  const handleChange = async (event: any) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  const handleSubmit = async (ev: any) => {
    ev.preventDefault();
    TagManager.dataLayer({
      dataLayer: {
        event: tagManagerEventsName.addPaymentInfo,
        finalPrice: finalPrice ?? 0,
      },
    });
    if (networkState.online === false) {
      errorState.setType('OFFLINE');
      return;
    }
    setProcessing(true);
    // console.log(networkState)
    // const payload = await stripe?.confirmCardPayment(clientSecret!, {
    //   payment_method: {
    //     card: elements?.getElement(CardElement)!,
    //   },
    // });
    const payload = await stripe?.confirmCardPayment(clientSecret!, {
      payment_method: {
        card: elements?.getElement(CardNumberElement)!,
        billing_details: {
          // include other billing details like customer name
          address: {
            postal_code: postalCode,
          },
        },
      },
    });
    if (payload?.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
      errorState.setType('STRIPE');
      return;
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: tagManagerEventsName.completePurchase,
          finalPrice: finalPrice ?? 0,
        },
      });
      setError(null);
      setProcessing(false);
      setSucceeded(true);
      setStripeResponse(payload?.paymentIntent);
      handleSuccessPayment();
    }
  };
  return (
    <>
      {paymentRequest && (
        <PaymentRequestButtonElement options={{ paymentRequest }} />
      )}
      <form
        id="payment-form"
        className={`flex flex-col ${paymentRequest ? 'mt-4' : ''}`}
        onSubmit={handleSubmit}
      >
        {/* <CardElement id="card-element" options={{}} onChange={handleChange} /> */}
        <div className="p-4 my-1 border-2 rounded">
          <CardNumberElement
            options={{
              placeholder: t('Card Number'),
              showIcon: true,
              style: {
                base: inputStyle,
              },
            }}
            onChange={handleChange}
          />
        </div>
        <div className="p-4 my-1 border-2 rounded">
          <CardCvcElement
            options={{
              style: {
                base: inputStyle,
              },
            }}
            onChange={handleChange}
          />
        </div>
        <div className="p-4 my-1 border-2 rounded">
          <CardExpiryElement
            options={{
              style: {
                base: inputStyle,
              },
            }}
            onChange={handleChange}
          />
        </div>
        <div className="p-4 my-1 border-2 rounded">
          <input
            type="text"
            className="w-full h-full border-0 placeholder:text-[slategray] focus:outline-none"
            placeholder={t<string>('Post Code')}
            value={postalCode}
            onChange={e => setPostalCode(e.target.value)}
          />
        </div>
        <img className="mt-4 min-h-[125px]" src={stripeLogo} alt="stripe" />
        <button
          type="submit"
          disabled={processing || disabled || succeeded}
          className=" relative p-0 mt-6 transition h-[70px] font text-white rounded-lg bg-[#FEB930]"
        >
          {/* TODO: handle spinner */}
          {processing ? (
            <span className="text-lg font-medium text-white">
              {t<string>('Processing…')}
            </span>
          ) : (
            <>
              <span className="text-lg font-medium text-white">
                {t<string>('Pay Now')}
              </span>
            </>
          )}
        </button>
        {error && (
          <span className="flex items-center justify-center py-2 mt-2 text-base font-medium text-red-400 capitalize">
            {error}
          </span>
        )}
      </form>
    </>
  );
}
