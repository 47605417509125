import { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { ReactComponent as SelectedLogo } from '../assets/tipping/Selected-Tipping Logov2.svg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Api from '../api';
import { Onboard } from '../interfaces/onboard.interface';
import onboardingBadge from '../assets/images/onboarding-badge.png';
import FullPageLoading from './FullPageLoading';
import { useTranslation } from 'react-i18next';

type OnboardingForm = {
  fullName: string;
  email: string;
  password: string;
};

const schema = yup
  .object({
    fullName: yup.string().required().min(3),
    email: yup.string().required().email(),
    password: yup.string().required().min(6),
  })
  .required();

const Onboarding = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [onboard, setOnboard] = useState<Onboard | null>(null);
  let { token } = useParams();
  let [params] = useSearchParams();
  const redirect = params.get('redirect');
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<OnboardingForm>({ resolver: yupResolver(schema) });

  const onSubmit = handleSubmit(async data => {
    try {
      setError(null);
      const response = await Api.updateOnboarding({
        ...onboard,
        onboardingParam: token,
        merchant: { ...onboard?.merchant!, ...data },
      });
      const result = response.data;
      window.location.href = redirect!;
      // window.location.replace(redirect!);
    } catch (e) {
      setError('Sorry, There was an Error');
    }
  });

  const fetchOnboarding = useCallback(
    async (token: string) => {
      try {
        const response = await Api.getOnboarding(token!);
        const data = response.data;
        // TODO: setValues to the form
        setValue('fullName', data.merchant.fullName);
        setValue('email', data.merchant.email);
        setOnboard(data);
        setLoading(false);
      } catch (e) {
        navigate('/');
      }
    },
    [setValue, navigate],
  );

  useEffect(() => {
    if (token) {
      fetchOnboarding(token);
    }
  }, [fetchOnboarding, token]);

  if (loading) {
    return <FullPageLoading />;
  }

  return (
    <div className="flex h-screen">
      <div className="text-white flex flex-col p-6 pt-16 bg-gradient-to-br from-[#FFB400] to-[#C051FF] w-96">
        <SelectedLogo className="h-6" />
        <div className="flex flex-col items-center justify-center flex-1">
          <h1 className="text-4xl text-medium">
            {t<string>('Powerful, automated onboarding and verification')}
          </h1>
          <p className="text-base font-medium mt-9">
            {t<string>(
              'Connect Onboarding provides conversion-optimized, prebuilt UIs to seamlessly onboard your sellers and service providers, eliminating the operational complexity that comes with designing your own identity verification flows.',
            )}
          </p>
        </div>
        <div className="flex flex-col text-sm font-medium text-center text-opacity-60">
          <span>Copyright © 2022.</span>
          <span>Powered by myselected.org.</span>
          <span>All Rights Reserved</span>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center flex-1">
        <div className="flex">
          <img src={onboardingBadge} alt="onboarding" className="mb-6 w-96" />
        </div>
        <form className="flex flex-col" onSubmit={onSubmit}>
          {!onboard?.merchant.id && (
            <>
              <label
                htmlFor="fullname"
                className="my-2 text-base font-medium text-[#1E2F4D]"
              >
                {t<string>('Fullname')}
              </label>
              <input
                id="fullname"
                className="h-12 px-4 my-2 border-2 border-gray-300 rounded ring-amber-300 focus:ring-2 hover:ring-2 w-96"
                type="text"
                placeholder="Please enter your Fullname"
                {...register('fullName')}
              />
              {errors.fullName?.message && (
                <p className="px-4 py-1 text-sm font-medium text-red-400 capitalize">
                  * {errors.fullName?.message}
                </p>
              )}{' '}
            </>
          )}
          <label
            htmlFor="email"
            className="my-2 text-base font-medium text-[#1E2F4D]"
          >
            {t<string>('Email')}
          </label>
          <input
            id="email"
            className="h-12 px-4 my-2 border-2 border-gray-300 rounded ring-amber-300 focus:ring-2 hover:ring-2 w-96"
            type="text"
            placeholder="Please enter your Email"
            {...register('email')}
          />
          {errors.email?.message && (
            <p className="px-4 py-1 text-sm font-medium text-red-400 capitalize">
              * {errors.email?.message}
            </p>
          )}
          <label
            htmlFor="password"
            className="my-2 text-base font-medium text-[#1E2F4D]"
          >
            {t<string>('Password')}
          </label>
          <input
            id="password"
            className="h-12 px-4 my-2 border-2 border-gray-300 rounded ring-amber-300 focus:ring-2 hover:ring-2 w-96"
            type="password"
            placeholder="Please enter your Password"
            {...register('password')}
          />
          {errors.password?.message && (
            <p className="px-4 py-1 text-sm font-medium text-red-400 capitalize">
              * {errors.password?.message}
            </p>
          )}
          <button className="h-12 p-0 my-2 transition rounded font text-white shadow-lg bg-gradient-to-r from-[#FFB400] to-[#C051FF] w-96">
            {onboard?.merchant.id ? 'Login' : 'Connect'}
          </button>
          {error && (
            <span className="flex items-center justify-center py-2 mt-2 text-base font-medium text-red-400 capitalize">
              {error}
            </span>
          )}
        </form>
      </div>
    </div>
  );
};

export default Onboarding;
