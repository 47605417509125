import { useEffect, useRef, useState } from 'react';
import { ReactComponent as SSvg } from '../assets/tipping/s.svg';
import { TipDetails } from '../interfaces/tip-details.interface';
import WaveSurfer from 'wavesurfer.js';
import ThankYou from '../assets/sounds/thank-you.wav';
import TagManager from 'react-gtm-module';
import { tagManagerEventsName } from '..';

export default function UserDropDown({
  tipDetails,
}: {
  tipDetails: TipDetails | null;
}) {
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const wavesurfer = useRef<WaveSurfer | null>(null);
  const waveformRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (waveformRef.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#BCBCC0',
        progressColor: '#FFB21E',
        height: 40,
        cursorColor: '#FFB21E',
        cursorWidth: 3,
        barRadius: 1,
        barGap: 4,
        barWidth: 2,
        maxCanvasWidth: 250,
        scrollParent: false,
        responsive: true,
        hideScrollbar: true,
      });
      wavesurfer.current.load(tipDetails?.summaryVoiceUrl ?? ThankYou);
      wavesurfer.current.on('play', () => {
        setIsPaused(true);
      });
      wavesurfer.current.on('pause', () => {
        setIsPaused(false);
      });
      wavesurfer.current.on('finish', () => {
        setIsPaused(false);
      });
    }
  }, [wavesurfer, tipDetails?.summaryVoiceUrl]);

  const playVoice = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: tagManagerEventsName.playVoice,
        businessName: tipDetails?.businessName ?? '',
        serviceName: tipDetails?.serviceName ?? '',
        workspace: tipDetails?.workspace ?? '',
      },
    });
    wavesurfer.current?.play();
  };

  return (
    <div className="flex flex-col items-center w-full text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
      <div className="flex items-center w-full">
        {tipDetails?.serviceProfileUrl ? (
          <img
            src={tipDetails?.serviceProfileUrl}
            className="w-16 h-16 rounded-full"
            alt="logo"
          />
        ) : (
          <SSvg className="w-16 h-16" />
        )}
        <div className="flex flex-col items-start justify-center h-16 ml-2 text-[#1E2F4D]">
          <span className="text-lg font-semibold capitalize">
            {tipDetails?.businessName}
          </span>
          <span className="text-xs font-normal capitalize">
            {tipDetails?.serviceName}
          </span>
        </div>
        {/* <PlaySound summaryVoiceUrl={tipDetails?.summaryVoiceUrl} /> */}
      </div>
      <p className="mt-3 text-sm font-light text-zinc-400 ">
        {tipDetails?.serviceDescription}
      </p>
      {/* {tipDetails?.summaryVoiceUrl && (
        <div className="flex items-center justify-between h-16 rounded-[10px] bg-[rgba(221,221,221,0.4)] w-full px-4 mt-4">
          <button
            onClick={playVoice}
            type="button"
            className="mr-2 rounded-full bg-transparent border-[#FFB21E] border-[2px] transition w-12 h-12 flex items-center justify-center p-0 hide-tap-highlight"
          >
            {!isPaused ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`w-8 h-8 text-[#FFB21E]`}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <desc>Download more icon variants from https://tabler-icons.io/i/player-play</desc>
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M7 4v16l13 -8z" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`w-8 h-8 text-[#FFB21E]`}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <rect x="6" y="5" width="4" height="14" rx="1" fill="#FFB21E"></rect>
                <rect x="14" y="5" width="4" height="14" rx="1" fill="#FFB21E"></rect>
              </svg>
            )}
          </button>
          <div ref={waveformRef} className="flex-1 h-10"></div>
          <span className="text-sm font-normal text-[#8E8E93] ml-2">
            {wavesurfer.current?.getDuration().toFixed(2) ?? '0:00'}
          </span>
        </div>
      )} */}
    </div>
  );
}
