import TagManager from 'react-gtm-module';
import { useTranslation } from 'react-i18next';
import { tagManagerEventsName } from '..';
import { formatCurrencyWithOutSymbol } from '../utils/currency';
import { ReactComponent as Pound1 } from '../assets/coins/coin-1.svg';
import { ReactComponent as Pound2 } from '../assets/coins/coin-2.svg';
import { ReactComponent as Pound3 } from '../assets/coins/coin-3.svg';

function TipBox({
  id,
  active,
  percent,
  price,
  bill,
  changeTipBox,
}: {
  id: string;
  active: boolean;
  percent: number;
  price: number;
  bill: string | null;
  changeTipBox: (id: string) => void;
}) {
  const { t } = useTranslation();
  const activeBill = bill && typeof bill === 'string' ? +bill : 0;
  let finalPrice = price;
  if (activeBill > 0) {
    finalPrice = (activeBill / 100) * percent;
  }
  const finalPriceArray = formatCurrencyWithOutSymbol(finalPrice).split('.');

  console.log({
    active,
    percent,
    price,
    bill,
    finalPrice,
    activeBill,
  });

  const handleChangeTipBox = (id: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: tagManagerEventsName.selectPricePlan,
        price: price ?? 0,
        bill: bill ?? 0,
        percent: percent ?? 0,
      },
    });
    changeTipBox(id);
  };

  return (
    <div
      onClick={() => handleChangeTipBox(id)}
      className={`${
        active ? 'bg-[#FEB930] text-white' : 'text-[#C4C4C3] bg-[#eeeeee]'
      } ${
        activeBill > 0 ? 'h-44' : ' h-28'
      } relative flex items-center justify-around p-4 min-w-[120px] max-w-[120px] w-28 rounded-[4px] mr-4 flex-col shadow-md cursor-pointer select-none`}
    >
      {percent <= 10 && (
        <Pound1
          className={`absolute w-[90px] bottom-[25px] ${
            active ? 'text-[#FF9900]' : 'text-[#E0E0E0]'
          } ${activeBill > 0 ? 'bottom-[25px]' : 'bottom-[8px]'} `}
        />
      )}
      {percent > 10 && percent < 20 && (
        <Pound2
          className={`absolute w-[80px] bottom-[25px] ${
            active ? 'text-[#FF9900]' : 'text-[#E0E0E0]'
          } ${activeBill > 0 ? 'bottom-[25px]' : 'bottom-[8px]'} `}
        />
      )}
      {percent >= 20 && (
        <Pound3
          className={`absolute w-[100px] bottom-[25px] ${
            active ? 'text-[#FF9900]' : 'text-[#E0E0E0]'
          } ${activeBill > 0 ? 'bottom-[25px]' : 'bottom-[8px]'} `}
        />
      )}
      <span className="relative z-30 flex w-full py-1 text-3xl font-bold item-center">
        <div className="mr-1 -mt-1 text-lg font-medium">&#163;</div>
        <span>{finalPriceArray[0]}</span>
        <span className="text-lg place-self-end">.{finalPriceArray[1]}</span>
      </span>
      {activeBill > 0 && (
        <div className="z-30 flex flex-col">
          <span className="relative flex w-full py-1 text-lg font-medium">
            {percent}
            <div className="ml-1 mt-[-2px] text-sm font-medium">%</div>
          </span>
          <span className="flex text-base font-normal">
            {t<string>('of the bill')}
          </span>
        </div>
      )}
    </div>
  );
}

export default TipBox;
