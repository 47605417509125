import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Api from '../api';
import { useState } from 'react';

type OnboardingForm = {
  newPassword: string;
  confirmNewPassword: string;
};

const schema = yup
  .object({
    newPassword: yup.string().required().min(6),
    confirmNewPassword: yup
      .string()
      .required()
      .oneOf([yup.ref('newPassword'), null], 'Passwords must match')
      .min(6),
  })
  .required();

const Resetpassword = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<OnboardingForm>({ resolver: yupResolver(schema) });

  const onSubmit = handleSubmit(async data => {
    // try {
    //   setError(null);
    //   const response = await Api.updateOnboarding({
    //     ...onboard,
    //     onboardingParam: token,
    //     merchant: { ...onboard?.merchant!, ...data },
    //   });
    //   const result = response.data;
    //   window.location.href = redirect!;
    //   // window.location.replace(redirect!);
    // } catch (e) {
    //   setError('Sorry, There was an Error');
    // }
  });

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen bg-[#f9f9f9]">
      <form
        className="flex flex-col p-8 bg-white rounded-lg shadow-lg"
        onSubmit={onSubmit}
      >
        <label
          htmlFor="new-password"
          className="my-2 text-base font-medium text-[#1E2F4D]"
        >
          New Password
        </label>
        <input
          id="new-password"
          className="w-full h-12 px-4 my-2 border-2 border-gray-300 rounded ring-amber-300 focus:ring-2 hover:ring-2 lg:w-96"
          type="password"
          placeholder="Please enter your New Password"
          {...register('newPassword')}
        />
        {errors.newPassword?.message && (
          <p className="px-4 py-1 text-sm font-medium text-red-400 capitalize">
            * {errors.newPassword?.message}
          </p>
        )}
        <label
          htmlFor="confirm-password"
          className="my-2 text-base font-medium text-[#1E2F4D]"
        >
          Confirm Password
        </label>
        <input
          id="confirm-password"
          className="w-full h-12 px-4 my-2 border-2 border-gray-300 rounded ring-amber-300 focus:ring-2 hover:ring-2 lg:w-96"
          type="password"
          placeholder="Please confirm your New Password"
          {...register('confirmNewPassword')}
        />
        {errors.confirmNewPassword?.message && (
          <p className="px-4 py-1 text-sm font-medium text-red-400 capitalize">
            * {errors.confirmNewPassword?.message}
          </p>
        )}
        <button className="h-12 p-0 my-2 transition rounded font text-white shadow-lg bg-gradient-to-r from-[#FFB400] to-[#C051FF] w-full lg:w-96">
          Done
        </button>
        {error && (
          <span className="flex items-center justify-center py-2 mt-2 text-base font-medium text-red-400 capitalize">
            {error}
          </span>
        )}
      </form>
    </div>
  );
};

export default Resetpassword;
