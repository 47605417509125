import * as React from 'react';

type ErrorStateProviderProps = { children: React.ReactNode };
type ErrorType = 'OFFLINE' | 'STRIPE' | 'SERVER';

const ErrorStateContext = React.createContext<
  { isOpen: boolean; close: () => void; type: ErrorType; setType: (type: ErrorType) => void } | undefined
>(undefined);

function ErrorStateProvider({ children }: ErrorStateProviderProps) {
  const [errorModalStatus, setErrorModalStatus] = React.useState<boolean>(false);
  const [errorType, setErrorType] = React.useState<ErrorType>('OFFLINE');
  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const close = () => {
    setErrorModalStatus(false);
  };

  const open = () => {
    setErrorModalStatus(true);
  };

  const setType = (type: ErrorType) => {
    setErrorType(type);
    open();
  };

  return (
    <ErrorStateContext.Provider value={{ isOpen: errorModalStatus, close, type: errorType, setType }}>
      {children}
    </ErrorStateContext.Provider>
  );
}

function useErrorState() {
  const context = React.useContext(ErrorStateContext);
  if (context === undefined) {
    throw new Error('useErrorState must be used within a CountProvider');
  }
  return context;
}

export { ErrorStateProvider, useErrorState };
