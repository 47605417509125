import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';
import { MicrophoneIcon, XCircleIcon } from '@heroicons/react/solid';
import { useLongPress } from 'use-long-press';
import Api from '../api';
import WaveSurfer from 'wavesurfer.js';
import { tagManagerEventsName } from '..';
import TagManager from 'react-gtm-module';
import Notification from './notification';
import { ReactComponent as FeedbackVector } from '../assets/images/mic.svg';
import { ReactComponent as Mic } from '../assets/images/mic2.svg';
import { ReactComponent as PauseButton } from '../assets/record-svg/play-pause.svg';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { buildStyles } from 'react-circular-progressbar';

const MAX_RECORD_TIME = 30;

export default function RecordAudio({
  tipCode,
  setFeedBackId,
  setInitentLoading,
  feedBackId,
  resetFeedback,
  handleResetFeedback,
}: {
  tipCode: string | undefined;
  setFeedBackId: any;
  setInitentLoading: any;
  feedBackId: any;
  resetFeedback: boolean;
  handleResetFeedback: () => void;
}) {
  const { t } = useTranslation();
  const [recordingTime, setRecordingTime] = useState<number>(0);
  const [notification, setNotification] = useState<boolean>(false);
  const [holdColor, setHoldColor] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [voiceBlob, setVoiceBlob] = useState<Blob | null>(null);
  const [bottomSheetStatus, setBottomSheetStatus] = useState<boolean>(false);
  let intervalId = useRef<any>(null);
  // const [isPaused, setIsPaused] = useState<boolean>(false);
  const wavesurfer = useRef<WaveSurfer | null>(null);
  const waveformRef = useRef<HTMLDivElement | null>(null);
  const {
    status,
    startRecording,
    stopRecording,
    // pauseRecording,
    // resumeRecording,
    // previewAudioStream,
    clearBlobUrl,
    mediaBlobUrl,
  } = useReactMediaRecorder({
    onStop: (_, blob: Blob) => setVoiceBlob(blob),
    audio: true,
    askPermissionOnMount: false,
    stopStreamsOnStop: true,
    blobPropertyBag: {
      type: 'audio/wav',
    },
  });
  const notifyHold = () => {
    setHoldColor(true);
  };

  const bind = useLongPress(startRecording, {
    onStart: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: tagManagerEventsName.startRecording,
          tipCode: tipCode,
        },
      });
    },
    onCancel: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: tagManagerEventsName.cancelRecording,
          tipCode: tipCode,
        },
      });
      notifyHold();
    },
    onFinish: () => {
      TagManager.dataLayer({
        dataLayer: {
          event: tagManagerEventsName.stopRecording,
          tipCode: tipCode,
        },
      });
      stopRecording();
    },
    threshold: 500,
    captureEvent: true,
    cancelOnMovement: false,
  });

  useEffect(() => {
    if (resetFeedback) {
      retakeRecording();
    }
  }, [resetFeedback]);

  useEffect(() => {
    if (status === 'recording') {
      intervalId.current = setInterval(() => {
        setRecordingTime(state => state + 0.25);
      }, 250);
    } else {
      clearInterval(intervalId.current);
    }
  }, [status]);

  useEffect(() => {
    if (recordingTime > MAX_RECORD_TIME) {
      stopRecording();
    }
  }, [recordingTime, stopRecording]);

  useEffect(() => {
    document.body.classList.toggle('overflow-hidden', bottomSheetStatus);
  }, [bottomSheetStatus]);

  useEffect(() => {
    if (mediaBlobUrl) {
      wavesurfer.current?.load(mediaBlobUrl);
    }
  }, [mediaBlobUrl]);

  useEffect(() => {
    if (waveformRef.current) {
      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: '#BCBCC0',
        progressColor: '#FFB21E',
        height: 40,
        cursorColor: '#FFB21E',
        cursorWidth: 3,
        barRadius: 1,
        barGap: 4,
        barWidth: 2,
        maxCanvasWidth: 250,
        scrollParent: false,
        responsive: true,
        hideScrollbar: true,
      });
      wavesurfer.current.on('play', () => {
        setIsPaused(true);
      });
      wavesurfer.current.on('pause', () => {
        setIsPaused(false);
      });
      wavesurfer.current.on('finish', () => {
        setIsPaused(false);
      });
    }
  }, [wavesurfer]);

  const getLocalStream = () => {
    navigator.mediaDevices
      .getUserMedia({ video: false, audio: true })
      .then(stream => {
        setBottomSheetStatus(state => !state);
      })
      .catch(err => {
        setNotification(true);
        setTimeout(() => {
          setNotification(false);
        }, 3000);
      });
  };

  const handleBottomSheet = () => {
    if (!bottomSheetStatus) {
      getLocalStream();
    } else {
      setBottomSheetStatus(state => !state);
    }
    if (mediaBlobUrl) {
      retakeRecording();
    }
  };

  const createFeedback = async (tipCode: string, feedBack: any) => {
    setInitentLoading(true);
    try {
      const file = new File([voiceBlob!], `${Date.now()}.wav`, {
        type: voiceBlob!.type,
      });
      let formData = new FormData();
      formData.append('tipCode', tipCode);
      formData.append('feedBack', file);
      formData.append(
        'duration',
        recordingTime.toString() ?? wavesurfer.current?.duration().toString(),
      );
      const response = await Api.createFeedBack(formData);
      const data = response.data;
      // toast.success('Feedback Saved');
      setInitentLoading(false);
      setFeedBackId(data.feedBack.id);
    } catch (e) {
      // toast.error('Feedback Error');
      setInitentLoading(false);
    }
  };

  const applyRecording = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: tagManagerEventsName.addFeedback,
        tipCode: tipCode,
      },
    });
    setBottomSheetStatus(state => !state);
    createFeedback(tipCode!, mediaBlobUrl);
  };

  const retakeRecording = () => {
    setFeedBackId(null);
    setRecordingTime(0);
    clearBlobUrl();
    if (wavesurfer.current?.isPlaying()) {
      wavesurfer.current.stop();
    }
    handleResetFeedback();
  };

  const playRecording = () => {
    console.log(wavesurfer.current);
    console.log(wavesurfer.current?.getDuration());
    if (mediaBlobUrl) {
      if (wavesurfer.current?.isPlaying()) {
        wavesurfer.current?.pause();
      } else {
        wavesurfer.current?.play();
      }
    }
  };

  if (status === 'permission_denied') {
    return null;
  }

  return (
    <>
      <Notification
        show={notification}
        setShow={setNotification}
        type="error"
        title="Permission Denied"
        description="Please allow microphone access"
      />
      <div className="flex flex-col">
        {/* <div className="my-6 flex px-4 items-center justify-center rounded-lg bg-[#f8f8f8] h-16 w-full shadow">
          <h1
            className="flex items-center justify-center py-6 text-md lg:text-[18px] font-semibold text-[#FFB21E] cursor-pointer hide-tap-highlight"
            onClick={handleBottomSheet}
          >
            <MicrophoneIcon
              className="w-6 h-6 text-[#f2a313] mr-2"
              aria-hidden="true"
            />
            {!mediaBlobUrl ? (
              <span className="underline underline-offset-1">
              {t<string>('Record your Feedback')}
              </span>
            ) : (
              <span className="underline underline-offset-1">
                <>{t<string>('Retake')}</>
              </span>
            )}
          </h1>
        </div> */}
        {bottomSheetStatus && (
          <div
            onClick={handleBottomSheet}
            className="fixed top-0 bottom-0 left-0 right-0 z-40 bg-black opacity-60 hide-tap-highlight"
          ></div>
        )}
        <div
          className={`${
            bottomSheetStatus ? 'translate-y-0' : 'translate-y-80'
          } fixed flex-col bottom-0 left-0 right-0 z-50 flex bg-[#EBEBF0] h-80 transition-transform duration-500 ease-[cubic-bezier(0.39, 1.52, 0.46, 0.92)]`}
        >
          <div className="flex items-center justify-around h-20 bg-white">
            <div className="flex items-center justify-between w-full h-20 px-4 lg:px-0 lg:mx-auto lg:w-96">
              <div className="flex flex-col">
                {!mediaBlobUrl && status !== 'recording' && (
                  <span
                    className={`text-base font-bold text-black ${
                      holdColor && 'text-red-600'
                    }`}
                  >
                    Hold to record
                  </span>
                )}
                {!mediaBlobUrl && status === 'recording' && (
                  <span className="text-base font-bold text-black">
                    Release to preview
                  </span>
                )}
                {mediaBlobUrl && (
                  <span className="text-base font-bold text-black">
                    Tap to preview
                  </span>
                )}
                {!mediaBlobUrl && status !== 'recording' && (
                  <span className=" font-thin text-[#C4C4C4] text-sm">
                    Long touch to start recording
                  </span>
                )}
                {!mediaBlobUrl && status === 'recording' && (
                  <span className=" font-thin text-[#C4C4C4] text-sm">
                    Slide away to stop
                  </span>
                )}
              </div>
              <XCircleIcon
                onClick={handleBottomSheet}
                className="w-12 h-12 text-[#8E8E93] cursor-pointer hide-tap-highlight"
                aria-hidden="true"
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center flex-1">
            {!mediaBlobUrl && (
              <div className="flex items-center justify-center h-10">
                {/* <div className="flex items-center justify-center w-8 h-8 bg-[#b7000066] rounded-full">
                <div className="block w-4 h-4 bg-[#B70000] rounded-full"></div>
              </div> */}
                <span className="flex w-4 h-4">
                  <span
                    className={`absolute inline-flex w-4 h-4 bg-red-400 rounded-full opacity-75 ${
                      status === 'recording' && 'animate-ping'
                    }`}
                  ></span>
                  <span className="relative inline-flex w-4 h-4 bg-red-500 rounded-full"></span>
                </span>
                <span className=" text-xl text-[#494949] font-thin w-40 text-center">
                  00:{Math.trunc(recordingTime)} / 00:{MAX_RECORD_TIME}
                </span>
              </div>
            )}
            {mediaBlobUrl && (
              <div className="flex items-center justify-center h-10">
                <button
                  type="button"
                  onClick={applyRecording}
                  className="bg-[#FFB21E] text-white rounded-full w-28 mx-4 h-10 hide-tap-highlight"
                >
                  Apply
                </button>
                <button
                  type="button"
                  onClick={retakeRecording}
                  className="bg-white text-[#FFB21E] rounded-full w-28 mx-4 h-10 hide-tap-highlight"
                >
                  Retake
                </button>
              </div>
            )}
            {mediaBlobUrl ? (
              <button
                onClick={playRecording}
                className="my-4 rounded-full bg-[#f2a313] transition w-20 h-20 flex items-center justify-center p-0 hide-tap-highlight"
              >
                {isPaused ? (
                  <PauseButton className={`w-12 h-12 text-white`} />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-10 h-10 text-white"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <desc>
                      Download more icon variants from
                      https://tabler-icons.io/i/player-play
                    </desc>
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M7 4v16l13 -8z" />
                  </svg>
                )}
              </button>
            ) : (
              // <button
              //   className="my-4 rounded-full bg-[#f2a313] active:bg-gradient-to-br from-[#FFB400] to-[#C051FF] transition w-20 h-20 flex items-center justify-center p-0 hide-tap-highlight"
              //   {...bind()}
              // >
              //   <MicrophoneIcon
              //     className="w-10 h-10 text-white"
              //     aria-hidden="true"
              //   />
              // </button>
              <div className="flex items-center justify-center w-[100px] h-[100px]">
                <CircularProgressbarWithChildren
                  className="w-full h-full"
                  value={recordingTime}
                  maxValue={MAX_RECORD_TIME}
                  styles={buildStyles({
                    // Rotation of path and trail, in number of turns (0-1)
                    // rotation: 0.25,
                    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                    strokeLinecap: 'butt',
                    // Text size
                    textSize: '16px',
                    // How long animation takes to go from one percentage to another, in seconds
                    pathTransitionDuration: 0.5,
                    // Can specify path transition in more detail, or remove it entirely
                    // pathTransition: 'none',
                    // Colors
                    pathColor: `#ef4444`,
                    trailColor: '#d6d6d6',
                    backgroundColor: '#3e98c7',
                  })}
                >
                  <button
                    {...bind()}
                    className=" shrink-0 my-4 rounded-full bg-[#f2a313] active:bg-gradient-to-br from-[#FFB400] to-[#C051FF] transition w-20 h-20 flex items-center justify-center p-0 hide-tap-highlight"
                  >
                    <MicrophoneIcon
                      className="w-10 h-10 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </CircularProgressbarWithChildren>
              </div>
            )}
            <div className="flex items-center justify-center h-8">
              {status === 'recording' && !mediaBlobUrl && (
                <span className=" text-lg font-medium text-[#B70000] w-40 text-center">
                  {MAX_RECORD_TIME - Math.trunc(recordingTime)} Seconds left
                </span>
              )}
              {mediaBlobUrl && (
                <span className=" text-lg font-medium text-[#A6A6A6] w-40 text-center">
                  {Math.trunc(recordingTime)} Seconds
                </span>
              )}
            </div>
          </div>
        </div>
        <FeedbackVector className="mx-auto my-4" />
        <h1 className="text-sm text-center font-medium text-[#1E2F4D]">
          {t<string>('Would you like to leave us feedback?')}
        </h1>
        <div className="relative">
          {!mediaBlobUrl && !feedBackId ? (
            <button
              type="button"
              // disabled={intentLoading || !feedBackId}
              onClick={handleBottomSheet}
              className="relative p-0 mt-4 w-full h-[70px] font text-white rounded-lg bg-[#1E2F4D] disabled:opacity-70"
            >
              <div className="flex items-center justify-center">
                <Mic className="w-8 h-8 ml-2 text-white" aria-hidden="true" />
                <span className="ml-2 text-sm font-normal text-white">
                  {t<string>('Start Recording')}
                </span>
              </div>
            </button>
          ) : null}
          <div
            className={`flex items-center justify-between border-[3px] h-20 mt-4 rounded-[10px] border-[#BFBFBF] px-4 relative ${
              mediaBlobUrl ? '' : '!hidden'
            }`}
          >
            <button
              onClick={playRecording}
              type="button"
              className="mr-2 rounded-full bg-[#EDEDED] transition w-14 h-14 flex items-center justify-center p-0 hide-tap-highlight"
            >
              {isPaused ? (
                <PauseButton
                  className={`w-12 h-12 ${
                    mediaBlobUrl ? 'text-[#FFB21E]' : 'text-gray-400'
                  }`}
                />
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className={`w-8 h-8 ${
                    mediaBlobUrl ? 'text-[#FFB21E]' : 'text-gray-400'
                  }`}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="currentColor"
                  fill="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <desc>
                    Download more icon variants from
                    https://tabler-icons.io/i/player-play
                  </desc>
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M7 4v16l13 -8z" />
                </svg>
              )}
            </button>
            <div ref={waveformRef} className="flex-1 h-12"></div>
            <XCircleIcon
              onClick={retakeRecording}
              className={`w-14 h-14 ${
                mediaBlobUrl ? 'text-[#ffa000]' : 'text-[#ffa000]'
              } ml-2 cursor-pointer hide-tap-highlight`}
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
    </>
  );
}
