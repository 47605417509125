export function formatCurrency(number: string | number) {
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });
  if (typeof number === 'string') {
    return formatter.format(+number);
  }
  return formatter.format(number);
}

export function formatCurrencyWithOutSymbol(number: string | number) {
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    currencyDisplay: 'code',
  });
  if (typeof number === 'string') {
    return formatter
      .format(+number)
      .replace('GBP', '')
      .trim();
  }
  return formatter.format(number).replace('GBP', '').trim();
}
