import { ReactComponent as SelectedLogo } from '../assets/tipping/Selected-Tipping Logov2.svg';

const Home = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-screen bg-gradient-to-br from-[#FFB400] to-[#C051FF]">
      <SelectedLogo className="h-64" />
    </div>
  );
};

export default Home;
