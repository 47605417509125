import { Fragment, useState } from 'react';
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { useLocalStorage } from 'react-use';

const Cookies = () => {
  const [value, setValue, remove] = useLocalStorage('ck', false);
  const [show, setShow] = useState(!value);

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="fixed inset-0 flex items-end justify-end px-4 py-6 pointer-events-none z-[999]"
      >
        <div className="flex flex-col items-center w-full space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto min-h-32 ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-[#1E2F4D]">
                      Cookies Consent
                    </p>
                    <p className="mt-1 text-sm text-gray-500">
                      By clicking “Accept”, you agree to the storing of cookies
                      on your device to enhance site navigation, analyze site
                      usage, and assist in our marketing efforts.
                    </p>
                    <div className="flex items-center justify-end mt-4">
                      <button
                        type="button"
                        className="h-10 mr-6 text-sm font-medium text-[#f2a313] transition bg-white border border-[#f2a313] rounded w-36"
                        onClick={() => {
                          setShow(false);
                          remove();
                        }}
                      >
                        Reject
                      </button>
                      <button
                        type="button"
                        className="h-10 text-sm font-medium text-white transition bg-[#f2a313] rounded w-36"
                        onClick={() => {
                          setShow(false);
                          setValue(true);
                        }}
                      >
                        Accept
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      type="button"
                      className="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};
export default Cookies;
