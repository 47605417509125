import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import './i18n';
import './index.css';
import Home from './pages/Home';
import Tip from './pages/tip/Tip';
import Onboarding from './pages/Onboarding';
import Resetpassword from './pages/ResetPassword';
import { Suspense } from 'react';
import FullPageLoading from './pages/FullPageLoading';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import { ErrorStateProvider } from './contexts/error-state-context';
import ErrorStateDialog from './components/ErrorStateDialog';
import QrCode from './pages/QrCode';
import Cookies from './components/Cookies';
import smartlookClient from 'smartlook-client';
import { Toaster } from 'react-hot-toast';
import Receipt from './pages/tip/components/Receipt';

export const tagManagerEventsName = {
  playVoice: 'playVoice',
  addFeedback: 'addFeedback',
  startRecording: 'startRecording',
  cancelRecording: 'cancelRecording',
  stopRecording: 'stopRecording',
  selectPricePlan: 'selectPricePlan',
  selectCustomPricePlan: 'selectCustomPricePlan',
  addFees: 'addFees',
  createIntent: 'createIntent',
  addPaymentInfo: 'addPaymentInfo',
  completePurchase: 'completePurchase',
} as const;
export const tagManagerEvents = {
  [tagManagerEventsName.playVoice]: 'Business voice played',
  [tagManagerEventsName.addFeedback]: 'Voice feedback recorded by user',
  [tagManagerEventsName.startRecording]: 'Start Voice Recording',
  [tagManagerEventsName.cancelRecording]: 'Cancel Voice Recording',
  [tagManagerEventsName.stopRecording]: 'Stop Voice Recording',
  [tagManagerEventsName.selectPricePlan]: 'A User selects a price plan',
  [tagManagerEventsName.selectCustomPricePlan]:
    'A user selects a custom price plan',
  [tagManagerEventsName.addFees]: 'Add card fees',
  [tagManagerEventsName.createIntent]: 'Generate stripe intetnt',
  [tagManagerEventsName.addPaymentInfo]:
    'A user submits their payment information',
  [tagManagerEventsName.completePurchase]: 'completes a purchase',
} as const;

export default function App() {
  return (
    <Suspense fallback={<FullPageLoading />}>
      <ErrorStateProvider>
        <ErrorStateDialog />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/tip/:tipCode"
              element={
                <>
                  <Tip />
                  <Cookies />
                </>
              }
            />
            <Route path="/onboarding/:token" element={<Onboarding />} />
            <Route path="/reset-password" element={<Resetpassword />} />
            <Route path="/qr-code" element={<QrCode />} />
            <Route path="/receipt" element={<Receipt />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
        <Toaster />
      </ErrorStateProvider>
    </Suspense>
  );
}

if (process.env.NODE_ENV === 'production') {
  const tagManagerArgs: TagManagerArgs = {
    gtmId: 'GTM-W3CMSFS',
    events: tagManagerEvents,
  };
  TagManager.initialize(tagManagerArgs);
  Sentry.init({
    dsn: 'https://b7320318f86b4bd789abf33b6f383ca3@o914862.ingest.sentry.io/6250535',
    integrations: [new BrowserTracing()], // Set tracesSampleRate to 1.0 to capture 100%// of transactions for performance monitoring.// We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const bool = process.env.REACT_APP_SMARTLOOK;
if (bool === 'true') {
  smartlookClient.init('2f74be8c36490b8bfb5354493a041bbdc58db616', {
    region: 'eu',
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
