import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import InternetAccessError from '../assets/errors/internet-access-error.svg';
import InternetAccessError2 from '../assets/errors/internet-access-error-2.svg';
import AccessDenied from '../assets/errors/access-denied.svg';
import EmptyState from '../assets/errors/empty-state.svg';
import NetworkAccessErrors from '../assets/errors/network-access-errors.svg';
import ServerAccessErrors from '../assets/errors/server-access-errors.svg';
import NotFound from '../assets/errors/not-found.svg';
import { useErrorState } from '../contexts/error-state-context';

export default function ErrorStateDialog() {
  const errorState = useErrorState();
  const [err, setErr] = useState({
    url: InternetAccessError2,
    text: 'There is no internet connection'
  });
  let refDiv = useRef(null);

  useEffect(() => {
    switch (errorState.type) {
      case 'OFFLINE':
        setErr({
          url: InternetAccessError2,
          text: 'There is no internet connection'
        });
        break;
      case 'STRIPE':
        setErr({
          url: AccessDenied,
          text: 'Stripe payment failed.'
        });
        break;
      case 'SERVER':
        setErr({
          url: ServerAccessErrors,
          text: 'Server temporarily unavailable.'
        });
        break;
    }
  }, [errorState.type]);

  useEffect(() => {
    new Image().src = InternetAccessError;
    new Image().src = InternetAccessError2;
    new Image().src = AccessDenied;
    new Image().src = EmptyState;
    new Image().src = NetworkAccessErrors;
    new Image().src = ServerAccessErrors;
    new Image().src = NotFound;
  }, []);

  return (
    <Transition.Root show={errorState.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={errorState.close} initialFocus={refDiv}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-sm sm:w-full sm:p-6">
                <img src={err.url} className="min-w-[300px]" alt="internet error" ref={refDiv} />
                <h1 className="mt-6 text-lg font-semibold text-center">{err.text}</h1>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
