// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#preloader {
  overflow: hidden;
  background-color: #1e2f4d;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;
  color: #fff;
}

.preloaderNone {
  display: none;
}

.jumper {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  position: absolute;
  margin: auto;
  width: 50px;
  height: 50px;
}

#preloader .jumper > div {
  background-color: #fff;
  border-radius: 100%;
  animation-fill-mode: both;
  position: absolute;
  opacity: 0;
  width: 50px;
  height: 50px;
  animation: jumper 1s 0s linear infinite;
}

#preloader .jumper > div:nth-child(2) {
  animation-delay: 0.33333s;
}

#preloader .jumper > div:nth-child(3) {
  animation-delay: 0.66666s;
}
@keyframes jumper {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/FullPageLoading.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,yBAAA;EACA,OAAA;EACA,QAAA;EACA,MAAA;EACA,SAAA;EACA,eAAA;EACA,aAAA;EACA,WAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,OAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,cAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,sBAAA;EAGA,mBAAA;EAEA,yBAAA;EACA,kBAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EAEA,uCAAA;AACF;;AAEA;EAEE,yBAAA;AACF;;AAEA;EAEE,yBAAA;AACF;AAkBA;EACE;IACE,UAAA;IAEA,mBAAA;EAAF;EAEA;IACE,UAAA;EAAF;EAEA;IACE,UAAA;EAAF;AACF","sourcesContent":["#preloader {\n  overflow: hidden;\n  background-color: #1e2f4d;\n  left: 0;\n  right: 0;\n  top: 0;\n  bottom: 0;\n  position: fixed;\n  z-index: 9999;\n  color: #fff;\n}\n\n.preloaderNone {\n  display: none;\n}\n\n.jumper {\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  display: block;\n  position: absolute;\n  margin: auto;\n  width: 50px;\n  height: 50px;\n}\n\n#preloader .jumper > div {\n  background-color: #fff;\n  width: 10px;\n  height: 10px;\n  border-radius: 100%;\n  -webkit-animation-fill-mode: both;\n  animation-fill-mode: both;\n  position: absolute;\n  opacity: 0;\n  width: 50px;\n  height: 50px;\n  -webkit-animation: jumper 1s 0s linear infinite;\n  animation: jumper 1s 0s linear infinite;\n}\n\n#preloader .jumper > div:nth-child(2) {\n  -webkit-animation-delay: 0.33333s;\n  animation-delay: 0.33333s;\n}\n\n#preloader .jumper > div:nth-child(3) {\n  -webkit-animation-delay: 0.66666s;\n  animation-delay: 0.66666s;\n}\n\n@-webkit-keyframes jumper {\n  0% {\n    opacity: 0;\n    -webkit-transform: scale(0);\n    transform: scale(0);\n  }\n  5% {\n    opacity: 1;\n  }\n  100% {\n    -webkit-transform: scale(1);\n    transform: scale(1);\n    opacity: 0;\n  }\n}\n\n@keyframes jumper {\n  0% {\n    opacity: 0;\n    -webkit-transform: scale(0);\n    transform: scale(0);\n  }\n  5% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
