import { formatCurrencyWithOutSymbol } from '../utils/currency';
import { PlusIcon } from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Pound1 } from '../assets/coins/Tipping-Coins-1-Pound.svg';
import { ReactComponent as Pound2 } from '../assets/coins/Tipping-Coins-2-Pound.svg';
import { ReactComponent as Pound3 } from '../assets/coins/Tipping-Coins-3-Pound.svg';

export default function CustomTipBox({ bill, customTip, openModal }: any) {
  const { t } = useTranslation();
  const activeBill = +bill ?? 0;
  const ct = customTip ?? 0;
  const percentage = (ct / activeBill) * 100 ?? 0;
  const pecent = isNaN(percentage) || percentage === Infinity ? 0 : percentage;
  let finalPriceArray: Array<string> = [];
  if (customTip) {
    finalPriceArray = formatCurrencyWithOutSymbol(customTip).split('.');
  }

  return (
    <div
      onClick={openModal}
      className={`${
        customTip
          ? 'bg-white text-[#ffa000] border-dashed border-[3px] border-[#ffa000]'
          : 'text-[#c4c4c3] bg-[#f9f9f9] border-dashed border-[3px] border-[#c4c4c3]'
      } ${
        activeBill > 0 ? 'h-44' : ' h-28'
      } select-none relative transition flex items-center justify-around p-4 min-w-[120px] max-w-[120px] w-28 rounded-[4px] mr-5 flex-col shadow-md cursor-pointer`}
    >
      {customTip && customTip <= 10 && (
        <Pound1
          className={`absolute w-[90px] bottom-[25px] text-[#E0E0E0] ${
            activeBill > 0 ? 'bottom-[25px]' : 'bottom-[10px]'
          } `}
        />
      )}
      {customTip && customTip > 10 && customTip <= 15 && (
        <Pound2
          className={`absolute w-[80px] bottom-[25px] text-[#E0E0E0] ${
            activeBill > 0 ? 'bottom-[25px]' : 'bottom-[10px]'
          } `}
        />
      )}
      {customTip && customTip > 15 && (
        <Pound3
          className={`absolute w-[100px] bottom-[25px] text-[#E0E0E0] ${
            activeBill > 0 ? 'bottom-[25px]' : 'bottom-[10px]'
          } `}
        />
      )}
      {customTip ? (
        <>
          <span className="relative z-30 flex w-full py-1 text-3xl font-bold item-center">
            <div className="mr-1 -mt-1 text-lg font-medium">&#163;</div>
            {/* {formatCurrencyWithOutSymbol(customTip)} */}
            <span>{finalPriceArray[0]}</span>
            <span className="text-lg place-self-end">
              .{finalPriceArray[1]}
            </span>
          </span>
          {activeBill > 0 && (
            <div className="z-30 flex flex-col">
              <span className="relative flex w-full py-1 text-lg font-medium">
                {percentage.toFixed(2)}
                <div className="ml-1 mt-[-2px] text-sm font-medium">%</div>
              </span>
              <span className="flex text-base font-normal">
                {t<string>('of the bill')}
              </span>
            </div>
          )}
        </>
      ) : (
        <span
          className={`${
            activeBill > 0 ? 'items-start' : 'items-center justify-around'
          } flex w-full`}
        >
          <PlusIcon className="w-8 text-[#c4c4c3] h-14" aria-hidden="true" />
        </span>
      )}
      {activeBill > 0 && !customTip && (
        <div className="flex items-start w-full font-bold">
          {t<string>('Other')}
        </div>
      )}
    </div>
  );
}
