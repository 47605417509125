import { useState, useEffect } from 'react';
import { Blurhash } from 'react-blurhash';
import { useAsync } from 'react-use';
import Api from '../api';

export const Unsplash = () => {
  const [unSplashSrc, setUnSplashSrc] = useState<string | undefined>(undefined);
  const randomUnSplashImage = useAsync(Api.randomUnSplashImage, []);
  useEffect(() => {
    if (randomUnSplashImage?.value) {
      const img = new Image();
      img.onload = () =>
        setUnSplashSrc(randomUnSplashImage?.value?.urls.regular);
      // img.onerror = (...args) => reject(args);
      img.src = randomUnSplashImage?.value?.urls.regular;
    }
  }, [randomUnSplashImage]);

  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 w-full h-screen lg:absolute lg:h-1/2 lg:block z-1">
      {randomUnSplashImage?.value && !unSplashSrc && (
        <Blurhash
          hash={randomUnSplashImage.value?.blur_hash}
          width={'100%'}
          height={'100%'}
          resolutionX={32}
          resolutionY={32}
          punch={1}
        />
      )}
      {unSplashSrc && (
        <img
          className="h-screen lg:h-[412px] w-full object-cover"
          style={{
            filter: 'brightness(0.8)',
          }}
          src={unSplashSrc}
          alt={randomUnSplashImage.value?.alt_description}
        />
      )}
    </div>
  );
};

export default Unsplash;
