import './FullPageLoading.scss';

export default function FullPageLoading() {
  return (
    <div id="preloader">
      <div className="jumper">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
