import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as en from './locales/en/translation.json';
import * as es from './locales/es/translation.json';
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: en,
  },
  es: {
    translation: es,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  // .use(LanguageDetector)
  .init({
    // resources,
    //lng: 'es', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
i18n.loadResources(async () => {
  const headers = {
    clientId: 'TxAt8BQuDdKSawIZvih29bbBrRffXogUcY4yTnsdeTzPbeXz4A',
    clientSecret: 'zhHb7y5bJtnh0hLzvyScbeaJQg0KfZ9UmH0ZsQODsjQkMKMxqw',
  };
  const response = await window.fetch(
    'https://trans.selectedstartups.com/api/client/languages/en/translations',
    {
      headers,
    },
  );
  const enResult = await response.json();
  // console.log(enResult);
  return {
    en: {
      translation: enResult.default,
    },
    // es: {
    //   translation: es,
    // },
  };
});

export default i18n;
