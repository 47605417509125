import Axios from 'axios';
import { CreateFeedBack } from './interfaces/create-feedback.interface';
import { CreateIntentRequest } from './interfaces/create-intent-request.interface';
import { Onboard } from './interfaces/onboard.interface';
import { TipDetails } from './interfaces/tip-details.interface';
import { UnSplash } from './interfaces/unsplash.interface';

const axios = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 5000,
  headers: { 'Content-Type': 'application/json' }
});

const getOnboarding = (token: string) => {
  return axios.post<Onboard>(`/v1/onboarding`, {
    onboardingParam: token
  });
};

const updateOnboarding = (data: Partial<Onboard>) => {
  return axios.put<{ businessId: string; merchantId: string }>(`/v1/onboarding`, data);
};

const createPaymentIntent = (options: CreateIntentRequest) => {
  return axios.post<{ client_secret: string }>('/v1/tip', options);
};

const getServiceDetail = (tipCode: string) => {
  return axios.post<TipDetails>(`/v1/tip/${tipCode}`);
};

const createFeedBack = (data: FormData) => {
  return axios.post<CreateFeedBack>(`/v1/feed-back`, data, {
    headers: { 'content-type': 'application/x-www-form-urlencoded' }
  });
};

const getStripeCharge = (amount: number) => {
  return axios.get<{ amount: string; fee: number }>(`/v1/tip/fee?amount=${amount}`);
};

const randomUnSplashImage = async (): Promise<UnSplash> => {
  const url: any = new URL(`${process.env.REACT_APP_UNSPLASH_BASE_URL}/photos/random/`);
  const params = {
    client_id: `${process.env.REACT_APP_UNSPLASH_CLIENT_ID}`,
    orientation: 'landscape',
    query: 'restaurant',
    fit: 'crop',
    w: '1024',
    h: '300'
  };
  url.search = new URLSearchParams(params).toString();
  return fetch(url, {
    headers: {
      'Accept-Version': 'v1'
    }
  }).then(data => {
    return data.json();
  });
};

// const getPublicStripeKey = options => {
//   return window
//     .fetch(`/public-key`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json"
//       }
//     })
//     .then(res => {
//       if (res.status === 200) {
//         return res.json();
//       } else {
//         return null;
//       }
//     })
//     .then(data => {
//       if (!data || data.error) {
//         console.log("API error:", { data });
//         throw Error("API Error");
//       } else {
//         return data.publishableKey;
//       }
//     });
// };

const api = {
  createPaymentIntent,
  // getPublicStripeKey: getPublicStripeKey,
  getServiceDetail,
  getOnboarding,
  updateOnboarding,
  getStripeCharge,
  createFeedBack,
  randomUnSplashImage
};

export default api;
