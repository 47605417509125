import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import TagManager from 'react-gtm-module';
import { tagManagerEventsName } from '..';

type CustomTipForm = {
  amount: number;
};

const schema = yup
  .object({
    amount: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .positive()
      .min(0.5)
      .max(999)
      .required('Please enter a valid price'),
  })
  .required();

export default function CustomTipDialog({
  isModalOpen,
  closeModal,
  changeCustomBox,
  customTip,
}: {
  isModalOpen: boolean;
  closeModal: () => void;
  changeCustomBox: (num: number) => void;
  customTip: number | null;
}) {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
    setValue,
  } = useForm<CustomTipForm>({ resolver: yupResolver(schema) });
  let prevAmountValue = useRef<number>(NaN);

  const onSubmit = handleSubmit(({ amount }) => {
    TagManager.dataLayer({
      dataLayer: {
        event: tagManagerEventsName.selectCustomPricePlan,
        amount,
      },
    });
    changeCustomBox(amount);
    close();
  });

  const close = () => {
    clearErrors('amount');
    reset();
    closeModal();
  };

  const handleAmount = ({ target }: { target: HTMLInputElement }) => {
    const amount = target.value;
    const pattern = /^[0-9]*(\.[0-9]{0,2})?$/;
    const result = pattern.test(amount);
    if (!amount) {
      return;
    }
    // TODO: avoid 00005.25
    if (+amount < 0) {
      setValue('amount', 0);
      return;
    }
    if (!result || +amount > 999) {
      setValue('amount', prevAmountValue.current);
    } else {
      prevAmountValue.current = +amount;
    }
  };

  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={close}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-95" />
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <form
              onSubmit={onSubmit}
              className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-transparent rounded-lg shadow-xl"
            >
              <Dialog.Title
                as="h3"
                className="text-lg md:text-2xl font-medium leading-6 text-white text-center min-h-[50px] max-h-[50px]"
              >
                {t<string>('How much would you like to tip?')}
              </Dialog.Title>
              <div className="relative flex items-center justify-center my-4">
                <div className="absolute left-[40px] lg:left-[100px] text-2xl text-[#f2a313] font-bold top-[20px]">
                  &#163;
                </div>
                <input
                  className=" w-64 min-h-[72px] max-h-[72px] rounded-lg px-8 text-center border-2 focus:outline-none focus:ring-2 text-2xl font-medium"
                  placeholder={t('Amount')}
                  type="number"
                  step="any"
                  {...register('amount', {
                    onChange: handleAmount,
                  })}
                />
              </div>
              <div className="flex items-end justify-end mt-16 min-h-[50px] max-h-[50px]">
                <button
                  type="submit"
                  className="w-full min-h-[64px] max-h-[64px] text-2xl font-medium text-white rounded-lg bg-[#FEB930] border border-transparent focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                >
                  {t<string>('Done')}
                </button>
              </div>
              {errors.amount && (
                <div className=" min-h-[50px] max-h-[50px] flex items-center justify-center text-center capitalize w-full my-4 font-semibold text-base text-red-500">
                  {errors.amount?.message}
                </div>
              )}
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
