import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import QRCodeStyling from 'qr-code-styling';
import { useToImage } from '@hcorta/react-to-image';
import TippingIcon from '../assets/qrcode/tipping-icon.svg';
import { ReactComponent as Poster } from '../assets/qrcode/poster1.svg';

const QrCode = () => {
  const qrRef = useRef(null);
  const [count, setCount] = useState(0);
  const generatedQrCode = useRef<QRCodeStyling | null>(null);
  const { ref, getJpeg } = useToImage({
    quality: 1,
    pixelRatio: 1,
  });

  const queryParams = new URLSearchParams(window.location.search);

  const size = queryParams.get('size') || '250';
  const data =
    queryParams.get('data') ||
    'https://site.tipping.selectedstartups.com/tip/dwSLt-f45';
  const title = queryParams.get('title') || 'Selected Tipping';
  const desc =
    queryParams.get('desc') ||
    'Scan to send tip directly to the team at Tipping. \n all tips will be evenly.';

  useEffect(() => {
    if (qrRef.current && count < 1) {
      generatedQrCode.current = new QRCodeStyling({
        width: +size,
        height: +size,
        data: data,
        image: TippingIcon,
        dotsOptions: {
          color: '#2c3e50',
          type: 'square',
        },
        imageOptions: {
          crossOrigin: 'anonymous',
          margin: 2,
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
        },
        cornersDotOptions: {
          color: '#2c3e50',
          // gradient:{
          //   type:'radial',
          //   rotation
          // },
        },
      });
      generatedQrCode.current.append(qrRef.current);
      setCount(1);
    }
  }, [size, data, count]);

  useEffect(() => {
    if (count === 1 && ref && generatedQrCode.current) {
      generatedQrCode.current?.getRawData().then(data => {
        getJpeg();
      });
    }
  }, [count, ref, getJpeg]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <button
        type="button"
        className="w-36 rounded h-12 bg-green-500 text-white mb-8"
        onClick={() => getJpeg()}
      >
        Download
      </button>
      <div
        className="flex flex-col items-center justify-center relative h-[600px] w-[600px]"
        ref={ref}
      >
        <Poster className="absolute w-full h-full" />
        <div ref={qrRef} className="z-10 absolute top-[163px]"></div>
        <h1 className="absolute bottom-[75px] font-bold text-4xl text-gray-800">
          {title}
        </h1>
        <p className="absolute bottom-[30px] text-sm font-medium text-gray-600 w-96 text-center">
          {desc}
        </p>
      </div>
    </div>
  );
};

export default QrCode;
