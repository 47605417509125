import { useState } from 'react';
import CopyRight from '../../../components/CopyRight';
import { formatCurrency } from '../../../utils/currency';
import receiptEnd from '../../../assets/images/receipt-end.png';
import { XCircleIcon } from '@heroicons/react/solid';
import './Receipt.scss';
import { ReactComponent as Brand } from '../../../assets/images/brand.svg';

const ReceiptFailed = ({
  serviceName,
  amount,
  stripeRes,
  businessName,
  returnUrl,
}: any) => {
  const [today] = useState(new Date());
  const monthName = today.toLocaleString('en-US', { month: 'short' });
  const yearName = today.toLocaleString('en-US', { year: '2-digit' });
  const dformat =
    [today.getDate(), monthName, yearName].join(' ') +
    ' ' +
    [today.getHours(), today.getMinutes()].join(':');

  const redirect = () => {
    window.location.href =
      returnUrl ?? 'https://landing.tipping.selectedstartups.com/';
  };

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen py-8 overflow-y-auto max-w-screen bg-[#2C2C2C]">
      <Brand className="mx-auto mb-8" />
      <div className="tip-box">
        <div className="receipt">
          <div className="box">
            <div className="bar"></div>
          </div>
          <div className="paper">
            <img src={receiptEnd} alt="receipt" />
            <div className="flex flex-col items-center justify-center w-full p-6">
              <XCircleIcon className="h-16 w-16 text-[#E75050]" />
              <h1 className="text-[#E75050] font-semibold my-2 text-xl">
                Failed payment!
              </h1>
              <span className="text-center text-[#161616] mt-4">
                *************************************
              </span>
              <span className="text-center text-[#161616] font-medium text-2xl">
                RECEIPT
              </span>
              <span className="text-center text-[#161616]">
                *************************************
              </span>
              <h2 className="text-[#433D46] font-semibold text-2xl py-2 border-dashed border-b-[1px] border-[#161616] w-full">
                Tipping
              </h2>
              <div className="flex items-center justify-between border-dashed border-b-[1px] border-[#161616] w-full py-2 mb-4">
                <span className="text-[#161616] font-medium text-sm">
                  Date and time:
                </span>
                <span className="text-[#161616] font-semibold text-base">
                  {dformat}
                </span>
              </div>
              <div className="flex items-center justify-between w-full py-1">
                <span className="text-[#161616] font-medium text-sm">
                  Company:
                </span>
                <span className="text-[#161616] font-semibold text-base">
                  {businessName ?? 'Selected Geeks'}
                </span>
              </div>
              <div className="flex items-center justify-between w-full py-1">
                <span className="text-[#161616] font-medium text-sm">
                  Payment Method:
                </span>
                <span className="text-[#161616] font-semibold text-base">
                  {'Card'}
                </span>
              </div>
              <div className="flex items-center justify-between w-full py-1">
                <span className="text-[#161616] font-medium text-sm">
                  Tip for:
                </span>
                <span className="text-[#161616] font-semibold text-base">
                  {serviceName ?? 'Service Name'}
                </span>
              </div>
              <h2 className="flex items-center justify-between text-[#433D46] font-semibold text-2xl py-2 border-dashed border-b-[1px] border-t-[1px] border-[#161616] w-full my-4">
                <span>Total Paid</span>
                <span>{formatCurrency(amount ?? 26.83)}</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <button
        className="border-2 border-[#FFB937] font-medium text-[#FFB937] rounded-lg h-16 my-9 w-80 z-50 shadow-md"
        onClick={redirect}
      >
        Done
      </button>
      <div className="w-full z-[2]">
        <CopyRight />
      </div>
    </div>
  );
};

export default ReceiptFailed;
