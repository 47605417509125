import { useTranslation } from 'react-i18next';
import { ArrowNarrowRightIcon, ChevronLeftIcon } from '@heroicons/react/solid';
import RecordAudio from '../../../components/RecordAudio';
import { ReactComponent as Mic } from '../../../assets/images/mic2.svg';
import { useState } from 'react';

export const FeedbackStep = ({
  prevStep,
  nextStep,
  tipCode,
  setFeedBackId,
  feedBackId,
  intentLoading,
  setInitentLoading,
  onSubmit,
}: any) => {
  const [resetFeedback, setResetFeedback] = useState(false);
  const { t } = useTranslation();

  const handleResetFeedbackTrue = () => {
    setResetFeedback(true);
  };

  const handleResetFeedbackFalse = () => {
    setResetFeedback(false);
  };

  return (
    <>
      <div
        className="w-9 h-9 absolute left-5 top-5 flex items-center justify-center cursor-pointer z-[2] bg-white/70 rounded-full shadow"
        onClick={prevStep}
      >
        <ChevronLeftIcon
          className="w-8 h-8 text-[#1E2F4D]"
          aria-hidden="true"
        />
      </div>
      <div className="w-full flex flex-col p-4 lg:p-9 py-6 bg-white rounded-lg shadow-sm z-[3]">
        <RecordAudio
          tipCode={tipCode}
          setFeedBackId={setFeedBackId}
          setInitentLoading={setInitentLoading}
          feedBackId={feedBackId}
          resetFeedback={resetFeedback}
          handleResetFeedback={handleResetFeedbackFalse}
        />
        {feedBackId ? (
          <button
            type="button"
            disabled={intentLoading || !feedBackId}
            onClick={e => onSubmit(e)}
            className="relative p-0 mt-4 transition h-[70px] font text-white rounded-lg bg-[#FEB930] disabled:opacity-70"
          >
            {intentLoading ? (
              <span className="text-lg font-medium text-white">
                {t<string>('Processing…')}
              </span>
            ) : (
              <div className="flex items-center justify-center">
                <Mic className="w-8 h-8 ml-2 text-white" aria-hidden="true" />
                <span className="ml-2 text-sm font-medium text-white">
                  {t<string>('Save and Continue')}
                </span>
              </div>
            )}
          </button>
        ) : null}
        {feedBackId ? (
          <button
            type="button"
            onClick={handleResetFeedbackTrue}
            className="relative p-0 mt-4 transition h-[70px] font text-[#ffa000] rounded-lg border-4 border-[#ffa000]"
          >
            <>
              <span className="text-sm font-medium">{t<string>('Retake')}</span>
            </>
          </button>
        ) : null}
        {!feedBackId && (
          <button
            type="button"
            onClick={onSubmit}
            className="relative p-0 mt-4 transition h-[70px] font text-[#ffa000] rounded-lg border-4 border-[#ffa000]"
          >
            <>
              <span className="text-sm font-medium">{t<string>('Skip')}</span>
            </>
          </button>
        )}
      </div>
    </>
  );
};
export default FeedbackStep;
