import { useTranslation } from 'react-i18next';
import { getYear } from 'date-fns';

export default function CopyRight() {
  const year = getYear(new Date());
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center p-4 text-center text-white rounded-lg md:text-gray-400 lg:p9">
      <a
        href="https://www.selected.org/privacy-policy"
        target="_blank"
        rel="noreferrer"
        className="text-sm "
      >
        {t<string>('Privacy Policy')} | {t<string>('Terms of Use')}
      </a>
      <span className="text-xs">
        Copyright © {year}
        <a
          className="ml-1 text-yellow-500"
          href="https://www.selected.org"
          target="_blank"
          rel="noreferrer"
        >
          selected.org
        </a>
      </span>
      {/* <span className="text-sm">All Rights Reserved</span> */}
      <span className="text-xs">{`${process.env.REACT_APP_VERSION}`}</span>
    </div>
  );
}
